import { APP_ID_BON, APP_ID_DIMENSIONS, APP_ID_IMART } from '../../../properties/index';


/**
 * array of changelog entries:
 * date: used as label for one release
 * all: part of all apps
 * APP_ID (e.g. dimensions): only part of specific app
 */
export const CHANGELOG = [
  {
    date: 'September 16, 2024',
    entries: {
      [APP_ID_IMART]: [
        {
          text: 'New dashboard type available: Open Science Tab 3'
        }
      ],
      all: [
        {
          text: 'Improved sharing components'
        }
      ]
    }
  },
  {
    date: 'August 19, 2024',
    entries: {
      all: [
        {
          text: 'Improved sharing of blocklists',
          text: 'Document View: Fixed number of patent family members if there are more than 100',
          text: 'General bug fixing'
        }
      ]
    }
  }, {
    date: 'June 27, 2024',
    entries: {
      [APP_ID_IMART]: [
        {
          text: 'New dashboard type available: Publication Tracker'
        }
      ],
      all: [
        {
          text: 'General bug fixing'
        }
      ]
    }
  },
  {
    date: 'May 29, 2024',
    entries: {
      [APP_ID_IMART]: [
        {
          text: 'New dashboard type available: Open Science Tab 4'
        }
      ],
      all: [
        {
          text: 'General bug fixing'
        }
      ]
    }
  },
  {
    date: 'April 24, 2024',
    entries: {
      [APP_ID_IMART]: [
        {
          text: 'Chemistry ontology',
          subentries: [
            { text: 'Added new BI compounds to chemistry ontology' },
          ],
        },
      ],
      [APP_ID_BON]: [
        {
          text: 'New ontology: Human Milk Oligosaccharides',
          subentries: [
            {
              text: 'Contains concepts and synonyms of complex carbohydrates that are present in human breast milk',
            },
          ],
        },
      ],
      all: [
        {
          text: 'Released new annotation: 2024r01',
          subentries: [
            {
              text: 'Methods; Magnitudes, Parameters and Properties; Companies and Institutions; Devices; Effects and Processes; Professions, Roles and Titles; Cell Lines; Substances: integration of BAO terminology being relevant to assays into several domains',
            },
            {
              text: 'Methods: hierarchy update in the assay branch; addition of missing assay vocabulary',
            },
            {
              text: 'Anatomy (Bacterial, Viral and Animal);  Effects and Processes; Magnitudes, Parameters and Properties: addition of CNS Research vocabulary',
            },
            {
              text: 'Drugs: addition of drug regimens; moved pharmacological agent subtree from Substances ontology into Drugs ontology and regularly updated the Drug ontology from INN and DrugHunter',
            },
            {
              text: 'Chemistry: removal of synonym redundancies; addition of specific PFAS classes',
            },
            {
              text: 'Professions, Roles and Titles: reorganization of hierarchy',
            },
            {
              text: 'Companies and Institutions: addition of missing companies and institutions',
            },
            {
              text: 'Magnitudes, Parameters and Properties: expansion of the color subtree (primary, secondary etc, colors by color model…)',
            },
          ],
        },
        {
          text: 'Added functionality to display maintenance/info messages to users',
        },
        { text: 'Document View: show author’s affiliations if available' },
        { text: 'General bug fixing and code refactoring' },
      ],
    },
  },
  {
    date: 'March 4, 2024',
    entries: {
      [APP_ID_IMART]: [
        {
          text: 'Added new feature: ReadCube Integration',
          subentries: [
            { text: 'Add multiple documents to ReadCube' },
            {
              text: 'Document View shows annotated PDF from ReadCube if document is available in ReadCube and PDF file is attached',
            },
            {
              text: 'Import ReadCube libraries in Quick Search and Advanced Search',
            },
          ],
        },
        { text: 'Renamed "Medmeme" repository to "Pharmaspectra"' },
      ],
      all: [
        {
          text: 'Added new beta feature: Query Builder (only available for selected people)',
          subentries: [
            { text: 'Document Finder -> Query Builder' },
            { text: 'Create complex and powerful searches' },
            {
              text: 'Covers most of the Advanced Search features without having to learn the API syntax',
            },
          ],
        },
        { text: 'Added RIS format to metadata export' },
        {
          text: 'Super/Support Admins: Apps can now be deactivated and are no longer visible in the App menu by default',
        },
        { text: 'Added examples to Sentence Analysis' },
        {
          text: 'Bug fixes',
          subentries: [
            {
              text: 'Document searches: fixed wrong number of document results',
            },
            {
              text: 'Reaction search: fixed layout of results view for large reactions',
            },
            {
              text: 'Compound search: removed sorting options for similarity results',
            },
          ],
        },
      ],
    },
  },
  {
    date: 'December 14, 2023',
    entries: {
      all: [
        { text: 'Added new feature: Sentence Analysis' },
        { text: 'Replaced “Custom Dashboards” by more flexible “Apps”' },
        { text: 'Improved sharing of document collections' },
        {
          text: 'Bulk Import for document IDs: added check for matching documents',
        },
        {
          text: 'Advanced Search: added “Application date” and “Filing date” to patent-specific properties',
        },
        { text: 'Quick search: fixed result order of exported metadata' },
        {
          text: 'Dimensions PDF linkouts: fixed problems with leading zeros in patent numbers',
        },
        { text: 'Chemistry search: fixed search for preparations' },
        {
          text: 'Super admins: added annotation version to backend and repository overview',
        },
        { text: 'General bug fixing' },
      ],
    },
  },
  {
    date: 'August 3, 2023',
    entries: {
      [APP_ID_IMART]: [
        {
          text: 'Super and support admins: Create apps based on search/document collections',
        },
      ],
      all: [
        { text: 'Improved sharing of search collections' },
        {
          text: 'Document searches: improved default sorting (“Relevance and date”)',
        },
        {
          text: 'Chemistry search: added number of total database entries to results view',
        },
        {
          text: 'Autocomplete fields: first entry automatically selectable via Enter key',
        },
        {
          text: 'Administration: improved domain and repository management',
          subentries: [
            { text: 'super admins can change domain colors' },
            {
              text: 'super admins can copy repository definitions to easily add the same repositories for multiple backends',
            },
          ],
        },
        { text: 'Updated or replaced deprecated external libraries' },
        { text: 'General bug fixing' },
      ],
    },
  },
  {
    date: 'July 14, 2023',
    entries: {
      all: [
        {
          text: 'new domain: plant diseases (includes plant disease name; sources Wikipedia, EPPO, PhytoPath and APS publications Plant Disease)',
        },
        { text: 'expansion of plant pathogen subtree within species ontology' },
        {
          text: 'addition of >7,000 polymer names and brands into the polymer ontology',
        },
        {
          text: 'improvements in chemistry ontology',
          subentries: [
            { text: 'contains more relevant compounds' },
            { text: 'removal of redundancies for common drug concepts' },
            {
              text: 'more granular content due to splitted concepts for charged and uncharged structures, e.g. carbonic acid and carbonic acid anions',
            },
            { text: 'new chemical classes: PFAS classes' },
          ],
        },
        {
          text: 'improved, granular classification within proteins and genes',
          subentries: [
            {
              text: 'split of proteins and corresponding genes into separate concepts',
            },
          ],
        },
        {
          text: 'disease ontology is now comprehensive with respect to rare diseases',
        },
        {
          text: 'integration of CDISC terminology into several domains (clinical trials, methods, magnitudes, institutions, companies, devices, effects, pharmacovigilance, professions, drugs, substances, toxicology, sciences)',
        },
        ,
      ],
    },
  },
  {
    date: 'June 1, 2023',
    entries: {
      all: [
        { text: 'Document searches: faster loading of results' },
        {
          text: 'Advanced Search: fixed and improved group highlighting',
          subentries: [
            {
              text: 'fixed group highlighting for queries with unmatched parentheses',
            },
            { text: 'added highlighting option for unmatched parentheses' },
          ],
        },
        {
          text: 'Bulk Import: added auto-recognition of input type and separator',
        },
        {
          text: 'Chemistry Search: added Domain Explorer to browse chemical classification and add chemical classes to the search',
        },
        {
          text: 'Co-occurrence Analysis: added “Saved Searches” dropdown to easily load stored queries',
        },
        { text: 'Library: added Metadata export via Export Center' },
        {
          text: 'Added “Copy to clipboard” functionality to many entries, e.g. concept details, document metadata etc.',
        },
        { text: 'Fixed and improved “Save Search” overlay' },
        { text: 'Updated PrimeReact version' },
        { text: 'General bug fixing' },
      ],
    },
  },
  {
    date: 'March 24, 2023',
    entries: {
      all: [
        {
          text: 'Advanced Search: redesign; added filter query support, saved searches, examples and help texts',
          subentries: [
            { text: 'redesigned page, especially editor toolbar' },
            { text: 'added support for filter queries' },
            {
              text: 'added dropdown to load saved searches directly into the editor',
            },
            { text: 'added examples and help text for certain features' },
          ],
        },
        {
          text: 'Document View overlay: added compound sorting, concept details, direct document link',
          subentries: [
            {
              text: 'chemical compounds on the right are now sorted by relevance (based on this document)',
            },
            {
              text: 'a click on an annotated concept or chemical compound on the right opens the Concept Details View',
            },
            {
              text: 'added buttons to get a direct link to the document or open it in a new tab',
            },
          ],
        },
        {
          text: 'Larger export now also available for metadata on all document search pages (listed in My Research -> My Exports)',
        },
        {
          text: 'Internal document IDs are now unique for all repositories (repository now added as a prefix)',
        },
        {
          text: 'Chemistry Search: bug fix: class OCIDs were not recognized as such',
        },
        { text: 'Added clear button to main search fields' },
        { text: 'General bug fixing' },
      ],
    },
  },
  {
    date: 'January 25, 2023',
    entries: {
      all: [
        {
          text: 'Chemistry Search: improved autocomplete and substructure highlighting, updated editor',
          subentries: [
            {
              text: 'autocomplete: automatically interprets user input such as InChIs, InChI keys, SMILES etc. while typing',
            },
            {
              text: 'suggestion list: separated chemical compounds and classes',
            },
            {
              text: 'added more checks to avoid invalid user input leading to error messages (e.g. mixing of compounds and classes)',
            },
            {
              text: 'added substructure highlighting in reaction search results (full match and substructure)',
            },
            { text: 'updated structure editor' },
            { text: 'added Mcule linkout' },
          ],
        },
        {
          text: 'Advanced search: added new search parameter and parentheses highlighting',
          subentries: [
            {
              text: 'added synonyms only search for concepts or groups (cmode:s)',
            },
            { text: 'added highlighting for matching parentheses' },
          ],
        },
        {
          text: 'Concept Details overlay: added more concept data',
          subentries: [
            { text: 'added physico-chemical properties (compounds only)' },
            { text: 'added co-occurrences of concept with specific domains' },
            { text: 'added Mcule linkout' },
          ],
        },
        {
          text: 'Fact Finder: improvements',
          subentries: [
            {
              text: 'added possibility to switch between only unidirect, causal and in addition symmetric non-causal relationships',
            },
            {
              text: 'added possibility to change the search mode of relations (ontological and exact)',
            },
            { text: 'added possibility to edit entities via Domain Explorer' },
            {
              text: 'some changes in the result table to display data from external sources',
            },
          ],
        },
        {
          text: 'Domain Explorer overlay: improved functionality to specify terms',
          subentries: [
            {
              text: 'now also possible to specify input consisting of multiple concepts, e.g. in Document View, Quick Search and Co-occurrence Analysis input fields',
            },
          ],
        },
        {
          text: 'Document View overlay: added metadata, compound sorting and improved header',
          subentries: [
            { text: 'added PMID and Internal document ID to Metadata section' },
            { text: 'improved overlay header design' },
          ],
        },
        { text: 'General bug fixing' },
      ],
    },
  },
  {
    date: 'November 23, 2022',
    entries: {
      [APP_ID_IMART]: [
        { text: 'Added BI full text linkouts to respective repositories' },
        { text: 'Integration of ReadCube' },
      ],
      all: [
        {
          text: 'Quick Search: improved filters and added example searches',
          subentries: [
            { text: 'Added filter categories to improve clarity' },
            { text: 'Inactive filters will only be deactivated, not removed' },
            { text: 'Added example searches' },
          ],
        },
        {
          text: 'Chemistry Search: added search by chemical classes, sorting and highlighting',
          subentries: [
            {
              text: 'Added highlighting of matching query structure to substructure search results',
            },
            { text: 'Added search for chemical classes' },
            {
              text: 'Added sorting for all search types (previously only for substructure search)',
            },
            { text: 'Added checks and messages for unsupported user inputs' },
            { text: 'Moved “include mixtures” filter to filter section' },
            { text: 'Cleaned up compound results display' },
          ],
        },
        {
          text: 'Document View: open in overlay, improved search in document',
          subentries: [
            {
              text: 'Document view now opening in overlay (Quick Search, Advanced Search, Biomarker, Co-occurrence Analysis and My Library)',
            },
            {
              text: 'Added navigation between the documents within the overlay',
            },
            {
              text: 'Pre-filled search within document with searched terms (coming from Quick Search, Co-occurrence Analysis and Biomarker)',
            },
            {
              text: 'Solved Quick Search shortcut problem for name-to-structure terms',
            },
            {
              text: 'Added the grouping/sorting switch to the domains in the Document View',
            },
            { text: 'Solved annotation popup positioning issue' },
          ],
        },
        {
          text: 'Super and support admins: added overview of available metadata fields: Global settings -> Repositories -> Show metadata',
        },
        {
          text: 'Added domain colors at more pages (e.g. Concept Details at Document View)',
        },
        { text: 'Bug fixing' },
      ],
    },
  },
  {
    date: 'October 6, 2022',
    entries: {
      all: [
        {
          text: 'Added export center page (My Research -> My Exports), available for chemistry search exports',
        },
        {
          text: 'Added domain colors to autocomplete fields and Domain Explorer',
        },
        {
          text: 'Super and support admins: added Edit User button to global user search',
        },
        {
          text: 'Links for single alerts in alert emails now forward to result list directly',
        },
        {
          text: 'Super admins: Added selection of default chemistry backend for organizations/suborganizations.',
        },
        { text: 'Bug fixes in new Chemistry Search' },
      ],
    },
  },
  {
    date: 'August 29, 2022',
    entries: {
      all: [
        { text: 'New and improved Chemistry Search' },
        { text: 'Added semantic search to Document View' },
        { text: 'Added children to concept details views' },
        { text: 'New design of dialogs' },
        { text: 'Sequence Exporter now part of My Library' },
        { text: 'Added “All concepts” option to Semantic Exporter' },
        { text: 'Administration of Chemistry Search repositories' },
      ],
    },
  },
  {
    date: 'August 03, 2022',
    entries: {
      all: [
        { text: 'Updated Advanced Search manual' },
        { text: 'Added more checks for correct Quick Search input' },
        { text: 'New design of action toasts' },
        { text: 'Standardized file names of downloads' },
        {
          text: 'Removed Semantic Exporter page and added export to My Library',
        },
        {
          text: 'Added info texts for domain filters in Quick Search and default features in administration',
        },
        {
          text: 'Rollout of new domains (ontologies) sorting, naming and coloring',
        },
        {
          text: 'Super and support admins: added settings matrix to easily bulk activate/deactivate features',
        },
      ],
    },
  },
  {
    date: 'June 28, 2022',
    entries: {
      all: [
        { text: 'Added domain facets/filters to Quick search' },
        { text: 'Added text distance option to Advanced search' },
        {
          text: 'Added grouping to search and document collections (private, shared by you, shared with you)',
        },
        {
          text: 'Concept details pages and overlays: concept definitions shown if available',
        },
        { text: 'Removed filter field from top 500 facets' },
        {
          text: 'Removed metadata download options for more than 1000 documents (not supported)',
        },
        {
          text: 'Fixed wrong state of demo suborganizations (now “demo” instead of “deactivated”)',
        },
        {
          text: 'Super and support admins: added settings matrix to easily activate/deactivate repositories and domains',
        },
        {
          text: 'Super and support admins: added user statistics at Global User Search',
        },
      ],
    },
  },
  {
    date: 'April 25, 2022',
    entries: {
      all: [
        { text: 'New annotation with new and improved ontologies' },
        {
          text: 'Added new domains',
          subentries: [
            {
              text: 'Antibody (separated out of proteins and genes ontology; sources Wikipedia, MeSH and OC classifications)',
            },
            {
              text: 'Department (includes all kind of company/business departments; sources Wikipedia and OC)',
            },
            {
              text: 'Industry (includes industrial sectors and branches; sources are Global Industry Classification Standard (GICS), International Standard Industrial Classification (ISIC), Australian and New Zealand Standard Industrial Classification (ANZSIC))',
            },
            {
              text: 'Medical device (separated out of device ontology; sources European Medical Device Nomenclature (EMDN) and Global Unique Device Identification Database (GUDID))',
            },
            {
              text: 'Pharmacokinetics (separated out of the effects, processes & functions ontology)',
            },
            {
              text: 'Pharmacovigilance (includes drug safety related concepts; sources are bioportal OAE, bioportal OVAE and OC classifications)',
            },
            {
              text: 'Phosphorylated protein (includes phosphorylated proteins; sources are dbPAF, dbPSP, EPSD, HisPhosSite, PhosPhAt, Phospho.ELM)',
            },
            {
              text: 'Profession (includes all kind of professions; sources Wikipedia and OC)',
            },
            {
              text: 'Science (includes scientific areas; sources are Wikipedia and OC)',
            },
          ],
        },
        {
          text: 'Changes in existing domains',
          subentries: [
            {
              text: 'Animal models subtree within methods ontology is now more comprehensive',
            },
            {
              text: 'Redundant protein concept naming has been removed; protein concepts have now a unique name including species information if available',
            },
          ],
        },
        {
          text: 'Added new repositories',
          subentries: [
            {
              text: 'Patents (IFI) - contains patent full texts from 45+ countries and bibliographic data from 100+ countries',
            },
            {
              text: 'Clinical trials - contains clinical trials from AACT, EudraCT, ENCePP, and ICTRP',
            },
          ],
        },
        { text: 'More filters available in quick search' },
        { text: 'New user role: Support admin' },
        { text: 'Added user creation date to user administration' },
        {
          text: 'Added csv download to semantic export (DataWarrior-compatible format)',
        },
        { text: 'Visual improvements in document view' },
        { text: 'Download of labeled compounds in document view' },
        { text: 'Improvement of adding/editing alerts' },
        {
          text: 'Fix: loading of quick search form following alerts email link',
        },
        { text: 'Fix: rounding error in quick search publications bar chart' },
        { text: 'Minor bug fixing' },
      ],
    },
  },
  {
    date: 'March 25, 2022',
    entries: {
      all: [
        { text: 'Added facets and dynamic search filters to quick search' },
        {
          text: 'Usage of annotated PDFs for document view (selected repositories only)',
        },
        {
          text: 'Added selector to switch between labeled and all compounds in document view',
        },
        { text: 'Improved annotation info overlays in document view' },
        { text: 'Improved autocomplete in domain explorer' },
        { text: 'Added SDF file export to compound search' },
        { text: 'Visual improvements' },
        { text: 'Bug fixing' },
      ],
    },
  },
  {
    date: 'January 27, 2022',
    entries: {
      all: [
        { text: 'New feature: Trend Analysis' },
        {
          text: 'Sorting of search collection, document collections and blocklists',
        },
        {
          text: 'Custom dashboards: handling of websites which are not embeddable',
        },
        { text: 'Bug fixing' },
      ],
    },
  },
  {
    date: 'December 8, 2021',
    entries: {
      all: [
        { text: 'Added global user search (Super Admins only)' },
        { text: 'Sharing and editing of blocklists' },
        { text: 'Saving and restoring searches from Fact Finder' },
        { text: 'SDF and SMI files download in Compound details' },
        { text: 'Add/remove search collections directly on My Dashboard page' },
        { text: 'Statistics for saved document searches' },
        { text: 'New feature: custom dashboards' },
        { text: 'Fixed visual problems in Firefox and Safari' },
      ],
    },
  },
  {
    date: 'October 27, 2021',
    entries: {
      [APP_ID_DIMENSIONS]: [{ text: 'Added manual for Advanced Search' }],
      all: [
        {
          text: 'Added OCID import option for Bulk import in Chemistry Search',
        },
        { text: 'Fixed bulk import of SMILES files in Chemistry Search' },
        { text: 'Added "Select all" option to Domain Explorer' },
        { text: 'Fixed bugs and visual problems in Firefox' },
      ],
    },
  },
  {
    date: 'October 07, 2021',
    entries: {
      [APP_ID_DIMENSIONS]: [
        { text: 'Added new Freshdesk register request widget' },
      ],
      all: [
        {
          text: 'Added shortcuts: load concepts from concept details into Co-occurrence Analysis or Chemistry Search (compounds only)',
        },
        {
          text: 'Added export options to metadata export, e.g. Selected documents, Top 100, etc.',
        },
        {
          text: 'Added more info icons and explanations to multiple pages, e.g. Document View and Chemistry Search',
        },
        {
          text: 'For admins: added repository features to activate/deactivate repositories for certain GUI features/apps, e.g. Document Search, Co-occurrence Analysis and Biomarker',
        },
        { text: 'Bug fixing' },
      ],
    },
  },
  {
    date: 'August 25, 2021',
    entries: {
      [APP_ID_DIMENSIONS]: [
        {
          text: 'Added video tutorials page and video links to all relevant pages',
        },
      ],
      all: [
        {
          text: 'Added search for reactants, reagents and products by using the reaction arrow and added filter by yield value at Reaction Search',
        },
        { text: 'New feature: restrict users to email domains' },
        { text: 'Minor bug fixing' },
      ],
    },
  },
];