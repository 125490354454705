import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog } from "primereact/dialog";
import { APP_PROPERTIES, FUNCTION_ROLES } from '../../properties/index';
import { hasUserRole } from '../../components/webapi/util';
import { Link } from 'react-router-dom';

export class AppTopbar extends Component {

    static defaultProps = {
        activeTopbarItem: null,
        topbarMenuActive: null,
        onMenuButtonClick: null,
        onTopbarItemClick: null,
        onTopbarMobileMenuButtonClick: null,
        layoutMode: 'horizontal'
    }

    static propTypes = {
        activeTopbarItem: PropTypes.string,
        topbarMenuActive: PropTypes.bool,
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        onTopbarMobileMenuButtonClick: PropTypes.func.isRequired,
        layoutMode: PropTypes.string
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.homepage = ''
        if (localStorage.token) {
            const tokenParts = localStorage.token.split('.');
            const tokenPayload = JSON.parse(atob(tokenParts[1]));
            const tokenLanding = tokenPayload.landing;
            if (tokenLanding) {
                let hasLanding = false
                tokenPayload.rol.forEach(role => {
                  if (role.toLowerCase().includes(tokenLanding)) {
                    FUNCTION_ROLES.forEach(role => {
                      if (role.name === tokenLanding) {
                        this.homepage = role.url
                        hasLanding = true
                      }
                    })
                  }
                })
                if (hasLanding === false) {
                    this.homepage = APP_PROPERTIES.HOME_PAGE
                }
            } else {
                this.homepage = APP_PROPERTIES.HOME_PAGE
            }
        }


        /*if (this.props.user.userDetails && this.props.user.userDetails.company && this.props.user.userDetails.company.landingPage) {
            if (this.props.user.userDetails.department.functionRoles.some(role => role.name === this.props.user.userDetails.company.landingPage)) {
                FUNCTION_ROLES.forEach(role => {
                    if (role.name === this.props.user.userDetails.company.landingPage) {
                        this.homepage = role.url
                    }
                })
            } else {
                this.homepage = APP_PROPERTIES.HOME_PAGE
            }
        } else {
            this.homepage = APP_PROPERTIES.HOME_PAGE
        }*/
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.userDetails !== prevProps.user.userDetails) {
            this.homepage = ''
            if (localStorage.token) {
                const tokenParts = localStorage.token.split('.');
                const tokenPayload = JSON.parse(atob(tokenParts[1]));
                const tokenLanding = tokenPayload.landing;
                if (tokenLanding) {
                    let hasLanding = false
                    tokenPayload.rol.forEach(role => {
                        if (role.toLowerCase().includes(tokenLanding)) {
                            FUNCTION_ROLES.forEach(role => {
                                if (role.name === tokenLanding) {
                                    this.homepage = role.url
                                    hasLanding = true
                                }
                            })
                        }
                    })
                    if (hasLanding === false) {
                        this.homepage = APP_PROPERTIES.HOME_PAGE
                    }
                } else {
                    this.homepage = APP_PROPERTIES.HOME_PAGE
                }
            }
        }
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    onAppLogoClick = () => {
        window.open(this.homepage, "_self");
    }

    onSupportClick = () => {

        if (APP_PROPERTIES.INCLUDE_FRESHDESK) {
            this.setState({
                displaySupportDialog: true
            });
        }
        else {
            window.open(APP_PROPERTIES.SUPPORT_FORM_URL, '_blank');
        }
    }

    onTutorialsClick = () => {
        window.open("/tutorials", '_blank');
    }

    renderServicesStatus = (serverStatus) => {

        if (!serverStatus) {
            return (
                <span style={{
                    color: 'white', background: 'gray', padding: 5,
                    marginRight: 30, top: 8, position: 'relative', borderRadius: 3,
                }}>
                    {`Services: -`}
                </span>
            );
        }
        else if (serverStatus.error) {
            return (
                <span style={{
                    color: 'white', background: '#F44336', padding: 5,
                    marginRight: 30, top: 8, position: 'relative', borderRadius: 3,
                }}>
                    {`Services: Not running`}
                </span>
            );
        }
        else {
            return (
                <span style={{
                    color: 'white', background: '#7CB342', padding: 5,
                    marginRight: 30, top: 8, position: 'relative', borderRadius: 3,
                }}>
                    {`Services: Running`}
                </span>
            );
        }
    }

    renderAppLogo = () => {

        //console.log("comp:", this.props.user.userDetails.company)
        //console.log("dep:", this.props.user.userDetails.department)

        let numberApplicationsHeaders = 0
        if (this.props.user.userDetails.department.useCustomHeader) {
            //console.log("use department headers")
            numberApplicationsHeaders = ((this.props.user.userDetails.department.applicationHeader1 !== '' && !this.props.user.userDetails.department.applicationHeader2 && !this.props.user.userDetails.department.applicationHeader)
                || (this.props.user.userDetails.department.applicationHeader2 !== '' && !this.props.user.userDetails.department.applicationHeader1 && !this.props.user.userDetails.department.applicationHeader3)
                || (this.props.user.userDetails.department.applicationHeader3 !== '' && !this.props.user.userDetails.department.applicationHeader1 && !this.props.user.userDetails.department.applicationHeader2)) ? 1 :
                ((this.props.user.userDetails.department.applicationHeader1 !== '' && this.props.user.userDetails.department.applicationHeader2 !== '' && !this.props.user.userDetails.department.applicationHeader3)
                    || (this.props.user.userDetails.department.applicationHeader2 !== '' && !this.props.user.userDetails.department.applicationHeader1 && this.props.user.userDetails.department.applicationHeader3 !== '')
                    || (this.props.user.userDetails.department.applicationHeader3 !== '' && this.props.user.userDetails.department.applicationHeader1 !== '' && !this.props.user.userDetails.department.applicationHeader2)) ? 2 :
                    (this.props.user.userDetails.department.applicationHeader3 !== '' && this.props.user.userDetails.department.applicationHeader1 !== '' && this.props.user.userDetails.department.applicationHeader2 !== '') ? 3 :
                        0
        } else if (this.props.user.userDetails.company.useCustomHeader) {
            //console.log("use company headers")
            numberApplicationsHeaders = ((this.props.user.userDetails.company.applicationHeader1 !== '' && !this.props.user.userDetails.company.applicationHeader2 && !this.props.user.userDetails.company.applicationHeader3)
                || (this.props.user.userDetails.company.applicationHeader2 !== '' && !this.props.user.userDetails.company.applicationHeader1 && !this.props.user.userDetails.company.applicationHeader3)
                || (this.props.user.userDetails.company.applicationHeader3 !== '' && !this.props.user.userDetails.company.applicationHeader1 && !this.props.user.userDetails.company.applicationHeader2)) ? 1 :
                ((this.props.user.userDetails.company.applicationHeader1 !== '' && this.props.user.userDetails.company.applicationHeader2 !== '' && !this.props.user.userDetails.company.applicationHeader3)
                    || (this.props.user.userDetails.company.applicationHeader2 !== '' && !this.props.user.userDetails.company.applicationHeader1 && this.props.user.userDetails.company.applicationHeader3 !== '')
                    || (this.props.user.userDetails.company.applicationHeader3 !== '' && this.props.user.userDetails.company.applicationHeader1 !== '' && !this.props.user.userDetails.company.applicationHeader2)) ? 2 :
                    (this.props.user.userDetails.company.applicationHeader3 !== '' && this.props.user.userDetails.company.applicationHeader1 !== '' && this.props.user.userDetails.company.applicationHeader2 !== '') ? 3 :
                        0
        }

        let applicationHeader1 = (this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.department.applicationHeader1) ?
            this.props.user.userDetails.department.applicationHeader1 : (!this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.company.useCustomHeader && this.props.user.userDetails.company.applicationHeader1)
                ? this.props.user.userDetails.company.applicationHeader1 : ''
        let applicationHeader2 = (this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.department.applicationHeader2) ?
            this.props.user.userDetails.department.applicationHeader2 : (!this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.company.useCustomHeader && this.props.user.userDetails.company.applicationHeader2)
                ? this.props.user.userDetails.company.applicationHeader2 : ''
        let applicationHeader3 = (this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.department.applicationHeader3) ?
            this.props.user.userDetails.department.applicationHeader3 : (!this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.company.useCustomHeader && this.props.user.userDetails.company.applicationHeader3)
                ? this.props.user.userDetails.company.applicationHeader3 : ''

        let fontColor = (this.props.user.userDetails.department.useCustomHeader && this.props.user.userDetails.department.fontColor) ? this.props.user.userDetails.department.fontColor :
            (this.props.user.userDetails.company.useCustomHeader && this.props.user.userDetails.company.fontColor) ? this.props.user.userDetails.company.fontColor : '#FFFFFF'

        /*let fontColorBorder = rgba(fontColor,0.29)
        console.log(fontColorBorder)*/
        //console.log(numberApplicationsHeaders)

        const convertHexToRGBA = (hexCode, opacity) => {
            let hex = hexCode.replace('#', '');

            if (hex.length === 3) {
                hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
            }

            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r},${g},${b},${opacity / 100})`;
        };

        let fontColorBorder = convertHexToRGBA(fontColor, 29)

        return (
            <span style={{
                //height: numberApplicationsHeaders !== 0 && 60
            }}>
                <Link to={this.homepage} className="p-link topbar-logo" >
                    <img
                        height='40px'
                        alt={`${APP_PROPERTIES.APP_NAME} application logo`}
                        //src={APP_PROPERTIES.HEADER_LOGO_APP}
                        src={(this.props.user.userDetails.department.logo && this.props.user.userDetails.department.useCustomHeader) ? this.props.user.userDetails.department.logo :
                            (this.props.user.userDetails.company.logo && this.props.user.userDetails.company.useCustomHeader) ?
                                this.props.user.userDetails.company.logo : APP_PROPERTIES.HEADER_LOGO_APP}
                        title="Home"
                        className="dimensions---line-ChemFinder"
                    //style={APP_PROPERTIES.HEADER_LOGO_APP_STYLE}
                    />
                    {/*<img src={APP_PROPERTIES.HEADER_LOGO_APP}
                            alt="Dimensions Chemistry application logo"
                            srcset="img/dimensions-line-chem-finder@2x.png 2x, img/dimensions-line-chem-finder@3x.png 3x"
                            className="dimensions---line-ChemFinder"
                       />*/}
                </Link>
                {/*<img src={APP_PROPERTIES.HEADER_LOGO_APP} className="dimensions---line-ChemFinder" />*/}
                {numberApplicationsHeaders !== 0 &&
                    <span style={{
                        display: 'inline-block', verticalAlign: 'bottom', paddingLeft: 15,
                        marginTop: numberApplicationsHeaders === 1 ? 8 : numberApplicationsHeaders === 2 ? 2 : 2, borderLeft: '1px solid', borderLeftColor: fontColorBorder
                    }}>
                        {applicationHeader1 !== '' &&
                            <div>
                                <label style={{ color: fontColor, fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 12.5 }}>{applicationHeader1}</label>
                            </div>
                        }
                        {applicationHeader2 !== '' &&
                            <div>
                                <label style={{ color: fontColor, fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 12.5 }}>{applicationHeader2}</label>
                            </div>
                        }
                        {applicationHeader3 !== '' &&
                            <div>
                                <label style={{ color: fontColor, fontSize: numberApplicationsHeaders === 1 ? 24 : numberApplicationsHeaders === 2 ? 17 : 12.5 }}>{applicationHeader3}</label>
                            </div>
                        }
                    </span>
                }
            </span>
        );
    }


    // TODO
    renderAppLogoMobile = () => {

        switch (APP_PROPERTIES.APP_ID) {

            case 'dimensions':
                return (
                    <Link to={this.homepage} className="p-link topbar-logo" >
                        <img
                            alt={`${APP_PROPERTIES.APP_NAME} application logo`}
                            //src={APP_PROPERTIES.HEADER_LOGO_APP}
                            src={this.props.user.userDetails.department.logo ? this.props.user.userDetails.department.logo : this.props.user.userDetails.company.logo ?
                                this.props.user.userDetails.company.logo : APP_PROPERTIES.HEADER_LOGO_APP}
                            title="Home"
                            className="dimensions---line-ChemFinder"
                        />
                    </Link>
                );

            case 'sciwalker_studio':
                return (
                    <Link to={this.homepage} className="p-link topbar-logo" style={{ marginLeft: 20, textDecoration: 'none' }}>
                        {/* <img alt="Application logo" src={APP_PROPERTIES.HEADER_LOGO_APP} title="Home" style={{ height: 50, padding: '5px 15px 5px 0px' }} /> */}
                        <span style={{ color: 'white', fontSize: '25px', top: 5, position: 'relative', fontWeight: 'bold' }}></span>
                        <span style={{ color: 'white', fontSize: '25px', top: 5, position: 'relative' }}>{APP_PROPERTIES.APP_NAME}</span>
                    </Link>
                );

            default:
                return (
                    <React.Fragment>
                        {APP_PROPERTIES.HEADER_LOGO_APP ?
                            <Link to={this.homepage} className="p-link topbar-logo" >
                                <img alt={`${APP_PROPERTIES.APP_NAME} application logo`}
                                    //src={APP_PROPERTIES.HEADER_LOGO_APP}
                                    src={this.props.user.userDetails.department.logo ? this.props.user.userDetails.department.logo : this.props.user.userDetails.company.logo ?
                                        this.props.user.userDetails.company.logo : APP_PROPERTIES.HEADER_LOGO_APP}
                                    title="Home"
                                    className="mobile-logo"
                                    onClick={this.onAppLogoClick}
                                    style={APP_PROPERTIES.HEADER_LOGO_APP_STYLE} />
                            </Link>
                            : null
                        }
                    </React.Fragment>
                );
        }
    }

    render() {

        let roleLabel;
        if (this.props.user.userDetails.superAdmin && !hasUserRole("ROLE_SUPPORT_ADMIN")) {
            roleLabel = 'Super admin';
        } else if (this.props.user.userDetails.superAdmin && hasUserRole("ROLE_SUPPORT_ADMIN")) {
            roleLabel = 'Support admin';
        } else if (this.props.user.userDetails.companyAdmin) {
            roleLabel = 'Organization admin';
        } else if (this.props.user.userDetails.departmentAdmin) {
            roleLabel = 'Suborganization admin';
        } else {
            roleLabel = 'User';
        }

        let numberApplicationsHeaders = 0
        if (this.props.user.userDetails.department.useCustomHeader) {
            //console.log("use department headers")
            numberApplicationsHeaders = ((this.props.user.userDetails.department.applicationHeader1 !== '' && !this.props.user.userDetails.department.applicationHeader2 && !this.props.user.userDetails.department.applicationHeader)
                || (this.props.user.userDetails.department.applicationHeader2 !== '' && !this.props.user.userDetails.department.applicationHeader1 && !this.props.user.userDetails.department.applicationHeader3)
                || (this.props.user.userDetails.department.applicationHeader3 !== '' && !this.props.user.userDetails.department.applicationHeader1 && !this.props.user.userDetails.department.applicationHeader2)) ? 1 :
                ((this.props.user.userDetails.department.applicationHeader1 !== '' && this.props.user.userDetails.department.applicationHeader2 !== '' && !this.props.user.userDetails.department.applicationHeader3)
                    || (this.props.user.userDetails.department.applicationHeader2 !== '' && !this.props.user.userDetails.department.applicationHeader1 && this.props.user.userDetails.department.applicationHeader3 !== '')
                    || (this.props.user.userDetails.department.applicationHeader3 !== '' && this.props.user.userDetails.department.applicationHeader1 !== '' && !this.props.user.userDetails.department.applicationHeader2)) ? 2 :
                    (this.props.user.userDetails.department.applicationHeader3 !== '' && this.props.user.userDetails.department.applicationHeader1 !== '' && this.props.user.userDetails.department.applicationHeader2 !== '') ? 3 :
                        0
        } else if (this.props.user.userDetails.company.useCustomHeader) {
            //console.log("use company headers")
            numberApplicationsHeaders = ((this.props.user.userDetails.company.applicationHeader1 !== '' && !this.props.user.userDetails.company.applicationHeader2 && !this.props.user.userDetails.company.applicationHeader3)
                || (this.props.user.userDetails.company.applicationHeader2 !== '' && !this.props.user.userDetails.company.applicationHeader1 && !this.props.user.userDetails.company.applicationHeader3)
                || (this.props.user.userDetails.company.applicationHeader3 !== '' && !this.props.user.userDetails.company.applicationHeader1 && !this.props.user.userDetails.company.applicationHeader2)) ? 1 :
                ((this.props.user.userDetails.company.applicationHeader1 !== '' && this.props.user.userDetails.company.applicationHeader2 !== '' && !this.props.user.userDetails.company.applicationHeader3)
                    || (this.props.user.userDetails.company.applicationHeader2 !== '' && !this.props.user.userDetails.company.applicationHeader1 && this.props.user.userDetails.company.applicationHeader3 !== '')
                    || (this.props.user.userDetails.company.applicationHeader3 !== '' && this.props.user.userDetails.company.applicationHeader1 !== '' && !this.props.user.userDetails.company.applicationHeader2)) ? 2 :
                    (this.props.user.userDetails.company.applicationHeader3 !== '' && this.props.user.userDetails.company.applicationHeader1 !== '' && this.props.user.userDetails.company.applicationHeader2 !== '') ? 3 :
                        0
        }
        let fontColor = (this.props.user.userDetails.department.fontColor && this.props.user.userDetails.department.useCustomHeader) ? this.props.user.userDetails.department.fontColor :
            (this.props.user.userDetails.company.fontColor && this.props.user.userDetails.company.useCustomHeader) ? this.props.user.userDetails.company.fontColor : '#FFFFFF'

        let backgroundColor = (this.props.user.userDetails.department.backgroundColor && this.props.user.userDetails.department.useCustomHeader) ? this.props.user.userDetails.department.backgroundColor :
            (this.props.user.userDetails.company.backgroundColor && this.props.user.userDetails.company.useCustomHeader) ? this.props.user.userDetails.company.backgroundColor : '#0d4667'

        let topbarClass = classNames('topbar-menu fadeInDown', { 'topbar-menu-active': this.props.topbarMenuActive })
        let horizontalIcon = (this.props.layoutMode === 'horizontal') &&
            <span>
                {this.renderAppLogo()}
            </span>

        //console.log(this.props.topbarMenuActive)
        //console.log(roleLabel)

        //console.log(this.props)

        return (
            <>
                <header>
                    <div className="layout-topbar"
                        style={{ backgroundColor: backgroundColor }}>
                        {horizontalIcon}

                        {/*this.renderAppLogoMobile()*/}

                        <button className="p-link menu-btn" onClick={this.props.onMenuButtonClick}>
                            <i className="material-icons">&#xE5D2;</i>
                        </button>
                        <button className="p-link topbar-menu-btn" style={{ border: `2px solid ${fontColor}`, width: 30, height: 30, background: backgroundColor, borderRadius: '50%', marginTop: 8 }} onClick={(e) => this.props.onTopbarMobileMenuButtonClick(e)}>
                            {/*<span style={{ paddingRight: 5 }}>
                            <i style={{ color: fontColor }} className="material-icons">&#xE853;</i>
        </span>*/}
                            <span style={{ color: fontColor, textAlign: 'center', paddingTop: 4, width: 30, height: 30 }}>
                                {`${this.props.user.userDetails.forename.charAt(0)}${this.props.user.userDetails.lastName.charAt(0)}`}
                            </span>
                            {/*<i style={{ color: fontColor }}
    className="material-icons">&#xE853;</i>*/}
                            {/* <span style={{ marginTop: 8 }} title={`Name: ${this.props.user.userDetails.forename} ${this.props.user.userDetails.lastName}, Company: ${this.props.user.userDetails.company.name}, Department: ${this.props.user.userDetails.department.name === 'default' ? 'Unassigned / Inactive regular users' : this.props.user.userDetails.department.name}, Role: ${roleLabel}`}>
                            <label
                                style={{ color: fontColor }}
                            >{`${this.props.user.username}`}</label>
        </span>*/}
                        </button>
                        <button className="p-link topbar-menu-btn" style={{ color: fontColor, marginTop: 15, marginRight: 20 }}
                            onClick={() => this.onSupportClick()} title='Support'>
                            {/*<i className="material-icons">exit_to_app</i>*/}
                            <span>Support</span>
                        </button>
                        {APP_PROPERTIES.APP_ID === 'dimensionss' &&
                            <button className="p-link topbar-menu-btn" style={{ color: fontColor, marginTop: 15, marginRight: 20 }}
                                title='Video Tutorials'
                                onClick={() => this.onTutorialsClick()} >
                                {/*<i className="material-icons">exit_to_app</i>*/}
                                <span>Video Tutorials</span>
                            </button>
                        }
                        <div className="layout-topbar-menu-wrapper">
                            <ul className={topbarClass} style={{ marginTop: numberApplicationsHeaders === 1 ? -42 : numberApplicationsHeaders === 2 ? -44 : numberApplicationsHeaders === 3 ? -46 : 0 }}>
                                <li className={classNames('profile-item', { 'active-topmenuitem': this.props.activeTopbarItem === 'profile' })}>
                                    <button className="p-link" title="User settings" onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                                        <span className="profile-image-wrapper" style={{ border: `2px solid ${fontColor}`, background: backgroundColor, color: fontColor, textAlign: 'center', paddingTop: 4 }}>
                                            {/*<i style={{ color: fontColor }}
    className="material-icons">&#xE853;</i>*/}

                                            {`${this.props.user.userDetails.forename.charAt(0)}${this.props.user.userDetails.lastName.charAt(0)}`}
                                        </span>

                                        {/*<span className="topbar-item-name profile-name">
                                        <span title={`Name: ${this.props.user.userDetails.forename} ${this.props.user.userDetails.lastName}, Company: ${this.props.user.userDetails.company.name}, Department: ${this.props.user.userDetails.department.name === 'default' ? 'Unassigned / Inactive regular users' : this.props.user.userDetails.department.name}, Role: ${roleLabel}`}>
                                            <label
                                                style={{ color: fontColor }}
                                            >{`${this.props.user.username}`}</label>
                                        </span>
    </span>*/}
                                    </button>

                                    <ul className="fadeInDown">
                                        {/*<li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">account_circle</i>
                                        <span>Profile</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">verified_user</i>
                                        <span>Privacy</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">settings_application</i>
                                        <span>Settings</span>
                                    </button>
                                </li>
                            */}

                                        <li role="menuitem">
                                            <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2', cursor: 'default' }}
                                                onClick={(e) => e.preventDefault()}>
                                                {/*<i className="material-icons">exit_to_app</i>*/}
                                                <div>
                                                    <div>{`${this.props.user.userDetails.forename} ${this.props.user.userDetails.lastName}`}</div>
                                                    <div style={{ color: '#757575' }}>{`${this.props.user.userDetails.email}`}</div>
                                                    <div style={{ color: '#757575' }}>{roleLabel}</div>
                                                    <br></br>
                                                    <div style={{ color: '#757575' }}>{this.props.user.userDetails.department.name === 'default' ? 'Unassigned / Inactive regular users' : this.props.user.userDetails.department.name}</div>
                                                </div>
                                            </button>
                                        </li>
                                        {/*<li role="menuitem">
                                        <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}>
                                            <span>My account</span>
                                        </button>
                        </li>*/}
                                        <li role="menuitem">
                                            <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}
                                                onClick={(e) => this.props.history.push('/my/account')}>
                                                {/*<i className="material-icons">exit_to_app</i>*/}
                                                <span>My account</span>
                                            </button>
                                        </li>
                                        {this.props.user.userDetails.highestAdminType !== 'NONE' ?
                                            <li role="menuitem">
                                                <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}
                                                    onClick={(e) => this.props.history.push('/administration')}>
                                                    {/*<i className="material-icons">exit_to_app</i>*/}
                                                    <span>Administration</span>
                                                </button>
                                            </li>
                                            :
                                            null
                                        }
                                        {this.props.user.userDetails.highestAdminType === 'ROLE_SUPER_ADMIN' && !hasUserRole("ROLE_SUPPORT_ADMIN") &&
                                            APP_PROPERTIES.ACTIVE_PAGES.backendAdministration ?
                                            <li role="menuitem">
                                                <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}
                                                    onClick={(e) => this.props.history.push('/backendadmin')}>
                                                    {/*<i className="material-icons">exit_to_app</i>*/}
                                                    <span>Backend Administration</span>
                                                </button>
                                            </li>
                                            : null
                                        }
                                        {hasUserRole("ROLE_CHANGELOG") ?
                                            <li role="menuitem">
                                                <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}
                                                    onClick={(e) => this.props.history.push('/changelog')}>
                                                    <span>Release notes</span>
                                                </button>
                                            </li>
                                            : null
                                        }
                                        <li role="menuitem">
                                            <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}
                                                onClick={(e) => this.props.history.push('/acknowledgements')}>
                                                {/*<i className="material-icons">exit_to_app</i>*/}
                                                <span>Acknowledgements</span>
                                            </button>
                                        </li>
                                        <li role="menuitem">
                                            <button className="p-link" style={{ borderBottom: '1px solid #b2b2b2' }}
                                                onClick={() => this.props.logout()}>
                                                {/*<i className="material-icons">exit_to_app</i>*/}
                                                <span>Sign out</span>
                                            </button>
                                        </li>
                                        <div style={{ padding: 10 }}>
                                            <div style={{ paddingTop: 5 }}>
                                                <span>
                                                    <a style={{ margin: 0, verticalAlign: 'middle' }} href="https://www.dimensions.ai" target="_blank" rel="noopener noreferrer">About Dimensions</a>
                                                </span>
                                                {APP_PROPERTIES.INCLUDE_ONETRUST &&
                                                    <>
                                                        <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>&#183;</span>
                                                        <span>
                                                            <a className="optanon-show-settings" style={{ marginLeft: 5, verticalAlign: 'middle' }}>Cookie settings</a>
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                            <div style={{ paddingTop: 5 }}>
                                                <span>
                                                    <a style={{ margin: 0, verticalAlign: 'middle' }} href="https://www.dimensions.ai/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
                                                </span>
                                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>&#183;</span>
                                                <span>
                                                    <a style={{ marginLeft: 5, verticalAlign: 'middle' }} href="https://www.dimensions.ai/policies-terms-legal/" target="_blank" rel="noopener noreferrer">Legal terms</a>
                                                </span>
                                            </div>
                                            <div style={{ paddingTop: 10 }}>
                                                <div style={{ paddingBottom: 10}}>
                                                <span style={{ color: '#757575' }}>© 2024 Digital Science & Research Solutions Inc. All Rights Reserved</span>
                                            </div>
                                            <span style={{ color: '#757575' }}>Version v24.09</span>
                                        </div>
                                    </div>
                            </ul>
                        </li>
                        <li style={{ paddingTop: 13 }} className={classNames('profile-item', { 'active-topmenuitem': this.props.activeTopbarItem === 'profile' })}>
                            <span className="topbar-item-name profile-name">
                                <span title='Support'>
                                    <button className="p-link" style={{ color: fontColor }}
                                        onClick={() => this.onSupportClick()}>
                                        {/*<i className="material-icons">exit_to_app</i>*/}
                                        <span>Support</span>
                                    </button>
                                </span>
                            </span>
                        </li>
                        {APP_PROPERTIES.APP_ID === 'dimensionss' &&
                            <li style={{ paddingTop: 13 }} className={classNames('profile-item', { 'active-topmenuitem': this.props.activeTopbarItem === 'profile' })}>
                                <span className="topbar-item-name profile-name">
                                    <span title='Video Tutorials'>
                                        <button className="p-link" style={{ color: fontColor }}
                                            onClick={() => this.onTutorialsClick()}>
                                            {/*<i className="material-icons">exit_to_app</i>*/}
                                            <span>Video Tutorials</span>
                                        </button>
                                    </span>
                                </span>
                            </li>
                        }
                        {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showSessionTimeLeft ?
                            <li>
                                {this.props.sessionTimeLeft ?
                                    <span style={{ color: 'white', marginRight: 30, top: 8, position: 'relative' }}>
                                        {`~ ${this.props.sessionTimeLeft} minutes left`}
                                    </span>
                                    : null
                                }
                            </li>
                            : null}
                        {APP_PROPERTIES.ACTIVE_FUNCTIONALITIES.showServerStatus ?
                            <li style={{ paddingTop: 5 }}>
                                {this.renderServicesStatus(this.props.serverStatus)}
                            </li>
                            : null}

                        {/* <li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'settings' })}>
                            <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                                <i className="topbar-icon material-icons">settings</i>
                                <span className="topbar-item-name">Settings</span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">palette</i>
                                        <span>Change Theme</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">star</i>
                                        <span>Favorites</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">lock</i>
                                        <span>Lock Screen</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">wallpaper</i>
                                        <span>Wallpaper</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'messages' })}>
                            <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'messages')}>
                                <i className="topbar-icon material-icons animated swing">&#xE0C9;</i>
                                <span className="topbar-badge animated rubberBand">5</span>
                                <span className="topbar-item-name">Messages</span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button className="topbar-message p-link">
                                        <img src="assets/layout/images/avatar1.png" width="35" alt="avatar1" />
                                        <span>Give me a call</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="topbar-message p-link">
                                        <img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />
                                        <span>Sales reports attached</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="topbar-message p-link">
                                        <img src="assets/layout/images/avatar3.png" width="35" alt="avatar3" />
                                        <span>About your invoice</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="topbar-message p-link">
                                        <img src="assets/layout/images/avatar2.png" width="35" alt="avatar2" />
                                        <span>Meeting today at 10pm</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="topbar-message p-link">
                                        <img src="assets/layout/images/avatar4.png" width="35" alt="avatar4" />
                                        <span>Out of office</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li className={classNames({ 'active-topmenuitem': this.props.activeTopbarItem === 'notifications' })}>
                            <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'notifications')}>
                                <i className="topbar-icon material-icons">notifications</i>
                                <span className="topbar-badge animated rubberBand">4</span>
                                <span className="topbar-item-name">Notifications</span>
                            </button>
                            <ul className="fadeInDown">
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">bug_report</i>
                                        <span>Pending tasks</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">event</i>
                                        <span>Meeting today at 3pm</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">cloud_download</i>
                                        <span>Download documents</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="material-icons">flight</i>
                                        <span>Book flight</span>
                                    </button>
                                </li>
                            </ul>
                            </li>*/}


                        {/*<li className={classNames('search-item', { 'active-topmenuitem': this.props.activeTopbarItem === 'search' })}
                            onClick={(e) => this.onTopbarItemClick(e, 'search')}>
                            <span className="md-inputfield">
                                <InputText type="text" />
                                <label>Search</label>
                                <i className="topbar-icon material-icons">search</i>
                            </span>
                        </li>*/}

                    </ul>
                </div>
            </div >
                </header >

            <Dialog
                //header={headerText ? headerText : 'Confirm'}
                visible={this.state.displaySupportDialog}
                modal={true}
                style={{ width: '40vw', minWidth: 300 }}
                onHide={(e) => { this.setState({ displaySupportDialog: false }) }}
                focusOnShow={false}
                dismissableMask={true}
            >
                <div>
                    <iframe
                        title="Feedback Form"
                        className="freshwidget-embedded-form"
                        id="freshwidget-embedded-form"
                        src="https://support-lc.dimensions.ai/widgets/feedback_widget/new?&widgetType=embedded&searchArea=no"
                        //src="https://helpdesk.uberresearch.com/widgets/feedback_widget/new?&widgetType=embedded&searchArea=no"
                        scrolling="no"
                        height="510px"
                        width="100%"
                        //frameborder="0"
                        frameBorder="0" >
                    </iframe>
                </div>
            </Dialog>
            </>
        );
    }
}

export default AppTopbar;